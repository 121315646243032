import { Link } from 'gatsby'
import React from 'react'

import './gift-back-button.scss'

interface GiftBackButtonProps {
  text: string
  color: string
  buttonAction?: (() => Promise<void> | string | void) | string
  actionButtonClose?: (cookie?: boolean) => void
}

function GiftBackButton({
  text,
  color,
  buttonAction,
  actionButtonClose,
}: GiftBackButtonProps) {
  return (
    <>
      <div className="gift-back-modal-container-button-underline">
        {typeof buttonAction === 'function' ? (
          <button
            onClick={() =>
              buttonAction
                ? buttonAction()
                : () => {
                    return false
                  }
            }
            style={{ color }}
          >
            {text}
          </button>
        ) : (
          <Link
            to={buttonAction ?? ''}
            onClick={() => actionButtonClose?.(false)}
          >
            <button style={{ color }}>{text}</button>
          </Link>
        )}
      </div>
    </>
  )
}

export default GiftBackButton
