function showAndHideScroll(status = false) {
  const body = document?.body
  let styleOverflow = 'hidden'

  if (body) {
    const elementGatsby = document.querySelector('#___gatsby') as HTMLDivElement

    if (!elementGatsby) {
      return false
    }

    if (status) {
      styleOverflow = ''
    }

    elementGatsby.style.overflow = styleOverflow
    body.style.overflow = styleOverflow

    return true
  }

  return false
}

export default showAndHideScroll
