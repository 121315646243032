import type { InsiderWishlistProduct } from 'src/components/wishlist/WishListInsiderUpdater'
import type { WishlistType } from 'src/typings/wishlist'

const findMissingProductIdsOnInsiderWishlistObject = (
  lists: WishlistType[]
) => {
  const productIds = lists?.flatMap((listInfo) =>
    listInfo.productList.map((productListInfo) => productListInfo.productId)
  )

  return productIds.filter(
    (productId) =>
      !window.insider_object.wishlist?.line_items?.some(
        (item: InsiderWishlistProduct) => item.product.id === productId
      )
  )
}

export default findMissingProductIdsOnInsiderWishlistObject
