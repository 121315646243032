import axios from 'axios'
import { get } from 'idb-keyval'

interface UserDataResponse {
  cpf: string
  email: string
}

export const getCredits = async (userDataParams: UserDataResponse[]) => {
  try {
    const { data } = await axios.post('/api/getOmniCredits', {
      email: userDataParams[0].email,
      cpf: userDataParams[0].cpf,
    })

    return data
  } catch (err) {
    console.error('Ocorreu um problema: ', err)

    return null
  }
}

export const getDataClient = async () => {
  const session = await get('fs::session')
  const userId = session?.person.id

  if (!userId) {
    return null
  }

  const response = await axios.post('/api/getClientData', {
    userId,
  })

  return getCredits(response?.data)
}
