import type { DataClientGiftBack } from '../GiftBack'
import type { DataClientGiftBackNotification } from '../modal/components/GiftBackButtonNotification/GiftBackButtonNotification'

export function verifyPhoneActive(
  dataClient: DataClientGiftBack | DataClientGiftBackNotification
) {
  const { bonusPhone, activeHomePhone, homePhone } = dataClient

  if (
    (!bonusPhone && !activeHomePhone) ||
    (bonusPhone && bonusPhone !== homePhone)
  ) {
    return false
  }

  return true
}
