import type { UseCartReturn } from 'src/sdk/cart'
import axios from 'axios'

import type { CartItemsProps } from '../sections/PromotionBar/PromotionBar'

export interface DataProductInsider {
  product: {
    product_id: string
    name: string
    taxonomy: string[]
    currency: string
    unit_price: string
    unit_sale_price: string
    url: string
    product_image_url: string
    size: string
    custom: {
      subcategoria_sku: string
      material_sku: string
      pedra_sku: string
      colecao_sku: string
      departamento: string
      relogio_material_caixa: string
      relogio_material_pulseira: string
      grupo_categoria_sku: string
      sku_id: string
      marca: string
      publico_sku: string
      categoria_sku: string
    }
  }
  quantity: number
  subtotal: string
}

export interface ItemCartProps {
  brand: {
    name: string
  }
  additionalProperty: Array<{
    value: string
  }>
  id: string
  quantity: number
  price: number
  listPrice: number
  categoriesIds: string[]
  specificationGroups: Array<{
    name: string
    specifications: Array<{
      name: string
      values: string[]
    }>
  }>
  slug: string
  breadcrumbList: {
    itemListElement: Array<{
      name: string
    }>
  }
  name: string
  image: Array<{
    url: string
  }>
  productId: string
}

interface ProductSpecifications {
  name: string
  specifications: Array<{
    name: string
    values: string[]
  }>
}

function getSpecificationValue(
  specificationName: string,
  productSpecifications?: ProductSpecifications
) {
  const specification = productSpecifications?.specifications?.find(
    (spec: { name: string }) => spec?.name === specificationName
  )

  const value =
    specification?.values?.[0] ??
    `sem ${specificationName
      ?.normalize('NFD')
      ?.replace(/[\u0300-\u036f]/g, '')}`

  return value.toUpperCase()
}

function hasItemInTheBasket(
  dataProducts: DataProductInsider[],
  itemCart: CartItemsProps
) {
  return dataProducts.some((prod) => prod?.product?.product_id === itemCart.id)
}

function customValues(itemCart: CartItemsProps, breadcrumbName: string[]) {
  const productSpecifications = itemCart?.specificationGroups?.find(
    (group: { name: string }) => group?.name === 'allSpecifications'
  )

  return {
    subcategoria_sku: breadcrumbName?.[1] || 'SEM SUBCATEGORIA',
    material_sku: getSpecificationValue('Material', productSpecifications),
    pedra_sku: getSpecificationValue('Pedras', productSpecifications),
    colecao_sku: getSpecificationValue('Coleção', productSpecifications),
    departamento:
      itemCart?.breadcrumbList?.itemListElement?.[0]?.name ||
      'SEM DEPARTAMENTO',
    relogio_material_caixa: getSpecificationValue(
      'Material da Caixa',
      productSpecifications
    ),
    relogio_material_pulseira: getSpecificationValue(
      'Material da Pulseira',
      productSpecifications
    ),
    grupo_categoria_sku: breadcrumbName?.[2] || 'SEM GRUPO SUBCATEGORIA SKU',
    sku_id: itemCart?.id,
    categoria_sku: breadcrumbName?.[0] || 'SEM CATEGORIA',
    marca: itemCart?.brand?.name || 'SEM MARCA',
    publico_sku: getSpecificationValue('Sugestão', productSpecifications),
  }
}

interface Totalizer {
  id: string
  name: string
  value: number
}

async function InsiderObjectBasket(
  itemsCart: CartItemsProps[],
  cart: UseCartReturn
) {
  const { total } = cart
  const { data } = await axios.post('/api/getOrderform', {
    orderformID: cart?.id,
  })

  const shippingValue = data?.totalizers?.find(
    (totalizer: Totalizer) => totalizer.id === 'Shipping'
  )

  let dataProducts: DataProductInsider[] = []

  itemsCart?.map((itemCart: CartItemsProps) => {
    if (!hasItemInTheBasket(dataProducts, itemCart)) {
      const breadcrumbItems = itemCart?.breadcrumbList?.itemListElement

      const breadcrumbName =
        breadcrumbItems?.map((itemList) => itemList.name).slice(0, -1) ?? []

      const taxonomy = breadcrumbName

      taxonomy?.shift()

      if (breadcrumbItems?.length) {
        dataProducts = [
          ...dataProducts,
          {
            product: {
              currency: 'BRL',
              product_id: itemCart?.productId,
              name: itemCart?.name,
              unit_price: itemCart?.listPrice?.toFixed(2),
              unit_sale_price: itemCart?.price?.toFixed(2),
              product_image_url: itemCart?.image?.[0]?.url,
              taxonomy: taxonomy || [],
              size: itemCart?.additionalProperty?.[0]?.value || '',
              custom: customValues(itemCart, breadcrumbName),
              url: `${window.origin}/${itemCart.slug}/p`,
            },
            quantity: itemCart?.quantity ?? 1,
            subtotal: String((itemCart?.price * itemCart?.quantity).toFixed(2)),
          },
        ]
      }
    }

    return dataProducts
  })

  window.insider_object = window.insider_object || {}
  window.insider_object.basket = {
    currency: 'BRL',
    total: total?.toFixed(2),
    line_items: dataProducts,
    shipping_cost: shippingValue?.value
      ? (shippingValue?.value / 100).toFixed(2)
      : '0.00',
  }
}

export default InsiderObjectBasket
