import axios from 'axios'

const getGiftBackByApi = async () => {
  const { data } = await axios.get('/api/getGiftBack')

  const convertToFloat = parseFloat(data?.replace(',', '.'))

  if (convertToFloat > 0) {
    return data
  }

  return ''
}

export const getDataClient = async (userId?: string) => {
  const response = await axios.post('/api/getClientData', {
    userId,
  })

  const activeHomePhone = response?.data?.[0]?.activeHomePhone
  let phoneTemp = response?.data?.[0]?.homePhone

  if (phoneTemp?.length > 11) {
    phoneTemp = phoneTemp?.substring(phoneTemp?.length, phoneTemp?.length - 11)
  }

  const userPhone = phoneTemp
  let balance = ''

  if (activeHomePhone) {
    balance = await getGiftBackByApi()
  }

  return { userPhone, dataClient: response?.data?.[0], balance }
}

export const getPinByApi = async () => {
  const { data } = await axios.get('/api/getPinByCrm')

  return data?.data?.sms?.split(' ')?.[2]
}

export const validatePin = async (pin?: string) => {
  return axios.post('/api/validatePin', {
    pin,
  })
}

export const hideModalGiftBack = () => axios.post('/api/setHideModalGiftBack')
