import type { Dispatch, SetStateAction } from 'react'
import React, { useEffect, useState } from 'react'
import { useUserContext } from 'src/contexts/user-context'
import showAndHideScroll from 'src/utils/showAndHideScroll'
import { deepObjectCopy } from 'src/utils'

import giftBackJson from './data/giftBack.json'
import { account } from '../../../../store.config'
import GiftBackModal from './modal'
import {
  getDataClient,
  getPinByApi,
  hideModalGiftBack,
  validatePin,
} from './services/api'
import { verifyPhoneActive } from './utils/verifyPhoneActive'

interface GiftBackFunctionsProps {
  next: string
  pinInput?: string
}

interface ButtonModalProps {
  action: (() => Promise<void> | string | void) | string
  color?: string
}

export interface ModalProps {
  submit?:
    | (({ next, pinInput }: GiftBackFunctionsProps) => Promise<{
        error: boolean
        message?: string
      } | void>)
    | (() => Promise<void>)
    | string
  buttonUnderLine?: ButtonModalProps
  buttonAlterNumberUnderLine?: ButtonModalProps
}

export interface DataClientGiftBack {
  activeHomePhone: boolean
  homePhone: string
  id: string
  userId: string
  hideModalGiftBack: boolean
  bonusPhone: string
}

interface GiftBackProps {
  isNotification?: boolean
  closeNotification?: () => void
  setUpdateNotification?: Dispatch<SetStateAction<boolean | undefined>>
}

interface IMessageError {
  [key: string]: string
}

const messageError: IMessageError = {
  invalidPin: 'Pin incorreto',
  attemptsExceeded: 'Limite de tentativas atingido',
}

function GiftBack({
  isNotification,
  closeNotification,
  setUpdateNotification,
}: GiftBackProps) {
  const [phone, setPhone] = useState<string>()
  const [typeModal, setTypeModal] = useState<string>('validateNumber')
  const [loading, setLoading] = useState(false)
  const [dataClient, setDataClient] = useState<DataClientGiftBack>()
  const [balance, setBalance] = useState<string>()
  const [propsModal, setPropsModal] = useState<ModalProps>()
  const [blockModal, setBlockModal] = useState<boolean>()
  const { user } = useUserContext()

  const updateDataClient = React.useCallback(async () => {
    const dataClientApi = await getDataClient(user?.id)

    setPhone(dataClientApi?.userPhone)
    setDataClient(dataClientApi?.dataClient)
    setBalance(dataClientApi?.balance)

    if (!dataClientApi?.userPhone) {
      showAndHideScroll(true)
    }

    if (
      dataClientApi?.dataClient &&
      verifyPhoneActive(dataClientApi?.dataClient) &&
      !isNotification &&
      blockModal === undefined
    ) {
      setBlockModal(true)
    }
  }, [blockModal, isNotification, user?.id])

  useEffect(() => {
    updateDataClient()
  }, [updateDataClient])

  const returnJsonData = () => {
    const dataJson = giftBackJson[typeModal as keyof typeof giftBackJson]
    const newDataJson = deepObjectCopy(dataJson)

    if (typeModal === 'pinSent') {
      const numberMask = `xx xxxxx-${phone?.substring(phone?.length - 4)}`

      newDataJson.text = `${dataJson?.text}${numberMask}`
    } else if (typeModal === 'myGiftBack' && balance) {
      newDataJson.text = `Você tem <strong>R$ ${balance}</strong> ${dataJson?.text}`
    }

    return newDataJson
  }

  const validateNumber = React.useCallback(
    async ({ next }: GiftBackFunctionsProps) => {
      setLoading(true)
      await getPinByApi()
      setLoading(false)

      setTypeModal(next)
    },
    [phone]
  )

  const pinSent = React.useCallback(
    async ({ next, pinInput }: GiftBackFunctionsProps) => {
      setLoading(true)

      const { data: pinValidated } = await validatePin(pinInput)

      setLoading(false)

      if (pinInput && !pinValidated?.error) {
        setBlockModal(false)

        if (balance) {
          setTypeModal(next)
        } else {
          setTypeModal('myGiftBackNoCoupon')
        }

        return { error: false }
      }

      if (pinValidated?.type === 'expiredPin') {
        validateNumber({ next: 'newPinSent' })

        return { error: true }
      }

      if (pinValidated?.error) {
        return {
          error: true,
          message: messageError[pinValidated?.type as string] || '',
        }
      }

      return { error: false }
    },

    [balance]
  )

  const redirectMyAccount = () => {
    const typeAccount = account === 'qavivara' ? 'secureqa' : 'secure'

    if (window?.location) {
      window.location.href = `https://${typeAccount}.vivara.com.br/account`
    }
  }

  const redirectSendPinAgain = React.useCallback(async () => {
    setTypeModal('sendPinAgain')
  }, [phone])

  const redirectForSupport = async () => {
    setTypeModal('contactSupport')
  }

  const closeGiftBack = async (actionModalInMasterData = true) => {
    if (actionModalInMasterData) {
      await hideModalGiftBack()
    }

    setTypeModal('')
    showAndHideScroll(true)
    closeNotification?.()
  }

  useEffect(() => {
    if (
      dataClient &&
      verifyPhoneActive(dataClient) &&
      balance !== undefined &&
      !blockModal
    ) {
      if (balance) {
        setTypeModal('myGiftBack')
      } else {
        setTypeModal('myGiftBackNoCoupon')
      }
    }
  }, [balance, blockModal, dataClient])

  useEffect(() => {
    if (typeModal === 'pinSent' || typeModal === 'newPinSent') {
      setPropsModal({
        submit: pinSent,
        buttonUnderLine: { action: redirectSendPinAgain },
        buttonAlterNumberUnderLine: { action: redirectMyAccount },
      })
    } else if (typeModal === 'sendPinAgain') {
      setPropsModal({
        submit: pinSent,
        buttonUnderLine: { action: redirectForSupport },
      })
    } else if (typeModal === 'myGiftBack') {
      setPropsModal({
        buttonUnderLine: { action: '/regras/entenda-gift', color: '#F08769' },
      })

      setUpdateNotification?.(true)
    } else if (
      typeModal === 'myGiftBackNoCoupon' ||
      typeModal === 'contactSupport'
    ) {
      setPropsModal({
        submit: '/institucional/atendimento',
        buttonUnderLine: { action: '/regras/entenda-gift' },
      })

      setUpdateNotification?.(true)
    } else {
      setPropsModal({
        submit: validateNumber,
        buttonUnderLine: { action: closeGiftBack },
      })
    }
  }, [pinSent, redirectSendPinAgain, typeModal, validateNumber])

  return (
    <>
      {phone &&
        ((!dataClient?.hideModalGiftBack && !blockModal) || isNotification) && (
          <>
            {typeModal && dataClient && balance !== undefined && (
              <GiftBackModal
                {...propsModal}
                type={typeModal}
                loading={loading}
                actionButtonClose={closeGiftBack}
                jsonData={returnJsonData()}
                isNotification={isNotification}
              />
            )}
          </>
        )}
    </>
  )
}

export default GiftBack
