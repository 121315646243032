import React, { useEffect } from 'react'
import addProductToInsiderWishlistObject from 'src/contexts/WishlistContext/utils/addProductToInsiderWishlistObject'
import { useProduct } from 'src/sdk/product/useProduct'

interface WishListInsiderUpdater {
  productId: string
}

export type InsiderWishlistProduct = {
  product: {
    id: string
    name: string
    taxonomy: string[]
    currency: string
    unit_price: number
    unit_sale_price: number
    url: string
    product_image_url: string
    custom: {
      subcategoria_sku: string
      material_sku: string | undefined
      pedra_sku: string | undefined
      colecao_sku: string | undefined
      departamento: string
      marca: string
      sku_id: string
      categoria_sku: string
      grupo_categoria_sku: string
      relogio_material_caixa: string | undefined
      relogio_material_pulseira: string | undefined
      publico_sku: string | undefined
    }
  }
  quantity: number
  subtotal: number
}

function WishListInsiderUpdater({ productId }: WishListInsiderUpdater) {
  const { data } = useProduct(productId)

  useEffect(() => {
    if (!data) {
      return
    }

    const productExists = window.insider_object.wishlist.line_items.some(
      (item: InsiderWishlistProduct) =>
        item.product.id === productId ||
        item.product.custom.sku_id === productId
    )

    if (productExists) {
      return
    }

    addProductToInsiderWishlistObject(data)
  }, [data])

  return <></>
}

export default WishListInsiderUpdater
