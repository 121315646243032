import { Link } from 'gatsby'
import React, { useEffect, useState, lazy, Suspense } from 'react'
import CloseIcon from 'src/components/icons/Close'
import showAndHideScroll from 'src/utils/showAndHideScroll'
import lazyRetry from 'src/utils/lazyRetry'

import type { ModalProps } from '../GiftBack'
import GiftBackButton from './components/GiftBackButton/GiftBackButton'
import './gift-back-modal.scss'
import OmniCreditsLabel from '../../OmniCredits/OmniCreditsLabel'
import SpinnerLoading from '../../SpinnerLoading'

const GiftBackPhoneIcon = lazy(() =>
  lazyRetry(
    () => import('src/images/svg/icon-phone-giftback'),
    'GiftBackPhoneIcon'
  )
)

const GiftBackIcon = lazy(() =>
  lazyRetry(() => import('src/images/svg/icon-giftback'), 'GiftBackIcon')
)

const GiftBackSupportIcon = lazy(() =>
  lazyRetry(
    () => import('src/images/svg/icon-support-giftback'),
    'GiftBackSupportIcon'
  )
)

interface GiftBackModalProps extends ModalProps {
  type: string
  loading: boolean
  actionButtonClose: (actionModalInMasterData?: boolean) => void
  jsonData: {
    icon: string
    text: string
    buttonText: string
    buttonUnderLineText: string | null
    buttonAlterNumberUnderLineText: string | null
    inputWithValue: boolean
    next: string
  }
  isNotification?: boolean
}

function GiftBackModal({
  type,
  loading,
  submit,
  buttonUnderLine,
  buttonAlterNumberUnderLine,
  actionButtonClose,
  jsonData,
  isNotification,
}: GiftBackModalProps) {
  const [pinInput, setPinInput] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<{
    error: boolean
    message?: string
  } | void>()

  const baseClass = 'gift-back-modal-container'

  useEffect(() => {
    if (!isNotification) {
      showAndHideScroll()
    }
  })

  return (
    <>
      <Suspense fallback={null}>
        <section
          className={`a-${baseClass} ${isNotification ? 'isNotification' : ''}`}
        >
          <div className={`${baseClass} ${type}`}>
            <div className={`${baseClass}__closeIcon`}>
              <button
                aria-label="Fechar"
                onClick={() => actionButtonClose(!isNotification)}
              >
                <CloseIcon color="#000" />
              </button>
            </div>
            <div className={`${baseClass}__image`}>
              <div>
                {jsonData?.icon === 'icon-giftback' && <GiftBackIcon />}
                {jsonData?.icon === 'icon-phone-giftback' && (
                  <GiftBackPhoneIcon />
                )}
                {jsonData?.icon === 'icon-support-giftback' && (
                  <GiftBackSupportIcon />
                )}
              </div>
            </div>
            <div className={`${baseClass}-message`}>
              <p
                className={type}
                dangerouslySetInnerHTML={{ __html: jsonData?.text }}
              />
            </div>
            {loading ? (
              <div className={`${baseClass}-loading`}>
                <span>
                  <SpinnerLoading />
                </span>
              </div>
            ) : !jsonData?.inputWithValue ? (
              submit && (
                <div className={`${baseClass}-buttonValidate`}>
                  {typeof submit === 'function' ? (
                    <button
                      className={type}
                      onClick={() => submit({ next: jsonData?.next })}
                    >
                      {jsonData?.buttonText}
                    </button>
                  ) : (
                    <button className={type}>
                      <>
                        {submit?.split('https')?.length > 1 ? (
                          <a href={submit} target="_blank" rel="noreferrer">
                            {jsonData?.buttonText}
                          </a>
                        ) : (
                          <Link
                            to={submit}
                            onClick={() => actionButtonClose(false)}
                          >
                            {jsonData?.buttonText}
                          </Link>
                        )}
                      </>
                    </button>
                  )}
                </div>
              )
            ) : (
              <div className={`${baseClass}-buttonValidateForPin`}>
                <div className={`${baseClass}-buttonValidateForPin__form`}>
                  <label htmlFor="codePin">Código Pin</label>
                  <input
                    type="text"
                    placeholder="00000"
                    id="codePin"
                    value={pinInput}
                    onChange={(e) => setPinInput(e?.target?.value)}
                  />
                  {errorMessage?.error && <p>{errorMessage?.message}</p>}
                </div>
                <button
                  onClick={async () =>
                    typeof submit === 'function' &&
                    setErrorMessage(
                      await submit({ next: jsonData?.next, pinInput })
                    )
                  }
                >
                  {jsonData?.buttonText}
                </button>
              </div>
            )}
            {jsonData?.buttonUnderLineText && (
              <GiftBackButton
                text={jsonData?.buttonUnderLineText}
                color={`${
                  buttonUnderLine?.color ? buttonUnderLine?.color : '#000'
                }`}
                buttonAction={buttonUnderLine?.action}
                actionButtonClose={actionButtonClose}
              />
            )}
            {jsonData?.buttonAlterNumberUnderLineText && (
              <GiftBackButton
                text={jsonData?.buttonAlterNumberUnderLineText}
                color="#F08769"
                buttonAction={buttonAlterNumberUnderLine?.action}
                actionButtonClose={actionButtonClose}
              />
            )}
            <OmniCreditsLabel />
          </div>
        </section>
      </Suspense>
    </>
  )
}

export default GiftBackModal
